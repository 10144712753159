//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TaskCardMixin from './TaskCardMixin'
import {
  uiSettingsStore,
} from '@/store'
import BaseEntityAvatar from '@/components/UI/BaseEntityAvatar.vue'

export default {
  name: 'TaskDeskCardFull',
  components: {
    BaseEntityAvatar,
  },
  mixins: [TaskCardMixin],
  props: {
    jid: String,
    taskObject: Object,
    standalone: Boolean,
    notHoverable: Boolean,
    dragMetadata: Object,
  },
  data () {
    return {
      hovered: false,
    }
  },
  computed: {
    showSectionText () {
      return uiSettingsStore.getters.currentDeskFilterKey !== 'section' && this.sectionText
    },
    topRowStyle () {
      const defaultStyle = 'lineHeight: 14px; padding: 12px 8px 4px 12px;'

      const fixedHeigh = 'height: 16px;' // needed to prevent size changing when top row is empty unless hovered
      const height = (this.task.deadLine !== null || this.sectionText !== null) ? '' : fixedHeigh

      return defaultStyle.concat(height)
    },
    isAlmostEmpty () {
      // checks if there are any notifications or tags in the task
      if (this.wasNoticed || this.hasUnread || this.hasTags || this.isPublic) return false
      return true
    },
    hasTags () {
      return this.task.tags.length > 0
    },
    isPublic () {
      return this.task.public
    },
    cardStyle () {
      return {
        backgroundColor: this.hovered ? this.cardColors.regular : this.cardColors.light,
        opacity: this.isDone ? 0.5 : 1,
      }
    },
    checkListProgress () {
      return this.task.numItems !== 0
        ? this.task.numCheckedItems / this.task.numItems
        : 0
    },
    // TODO: bring back when decided on behaviour
    // neverRead () {
    //   let chat = this.$store.getters.chat(this.task.jid)
    //   return chat && chat.lastReadMessageId == null
    // },
    // TODO: bring back when decided on behaviour
    // isAssigned () {
    //   let me = this.$store.getters.getUserId
    //   return this.task.assignee === me
    // },
    // TODO: bring back when decided on behaviour
    // indicator () {
    //   if (this.isAssigned) return 'assignee'
    //   if (this.task.owner === this.$store.getters.getUserId) return 'owner'
    //   return 'observer'
    // },
    // TODO: bring back when decided on behaviour
    // onlyMe () { // в задаче есть только я
    //   let me = this.$store.getters.getUserId
    //   return this.task.observers.length === 0 && (this.task.assignee === me || this.task.assignee === null) && this.task.owner === me
    // },
    // TODO: bring back when decided on behaviour
    // isInCopy () {
    //   let me = this.$store.getters.getUserId
    //   return this.task.assignee !== me && this.task.owner !== me
    // },
    // TODO: bring back when decided on behaviour
    // hasManyAssignees () {
    //   return this.task.assignee === null && this.task.observers.length > 0
    // },
    // TODO: bring back when decided on behaviour
    // isNew () {
    //   return this.task.taskStatus === 'new' && this.task.numUnread > 0
    // },
    // TODO: bring back when decided on behaviour
    // canChangeStatus () {
    //   return this.task.changeableFields.indexOf('task_status') !== -1
    // },
  },
  methods: {
    goToTask (ev) {
      window.goal('dashboardControls', { dashboardControls: 'Клик на карточку задачи' })

      this.navTo(ev, `/${this.currentTeamId}/chats/${this.task.jid}/`, this.task)
    },
    // TODO: uncomment when ellipsis (...) content is decided upon
    // editTask () {
    //   this.$store.dispatch('SHOW_MODAL', { instance: 'new-task', payload: { jid: this.task.jid } })
    // },
    dragStart (ev) {
      const data = {
        ...this.dragMetadata || {},
        num: this.task.num,
        jid: this.task.jid,
      }

      ev.dataTransfer.effectAllowed = 'all'
      ev.dataTransfer.setData('tadateam/x-task', JSON.stringify(data))
      ev.dataTransfer.setDragImage(ev.target, 203, 50)
      return true
    },
    // ?: unused?
    // changeStatus () {
    //   this.$store.dispatch('TASK_CHANGE_STATUS', { jid: this.task.jid })
    // },
    // TODO: uncomment when ellipsis (...) content is decided upon
    // openFullTask (ev) {
    //   if (ev.ctrlKey) {
    //     return
    //   }
    //   if (ev.shiftKey) {
    //     api.tasks.edit(this.task.jid, { pinned: !this.task.pinned, pinned_sort_ordering: 0 })
    //     ev.preventDefault()
    //     return
    //   }
    //   ev.preventDefault()

    //   !this.standalone && this.$store.dispatch('OPEN_CHAT', { chatId: !this.isThisTaskOnScreen ? this.task.jid : null })
    // },
    // ?: unused?
    // pinMove (ev) {
    //   let index = this.task.pinnedSortOrdering + (ev.shiftKey ? -1 : 1)
    //   api.tasks.edit(this.task.jid, { pinned: true, pinned_sort_ordering: index })
    // }
  },
}
